<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-description"
                label-cols-lg="2"
                :label="$t('COMMON.DESCRIPTION')"
                label-for="field-description-input"
              >
                <b-form-textarea
                  id="field-description-input"
                  v-model="dto.description"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                label-cols-lg="2"
                :label="$t('DATA_PACKAGES.PREVIEW_IMAGE')"
              >
                <b-form-file
                  v-if="dto.imageUrl === null"
                  v-model="image"
                  accept="image/jpeg, image/png"
                ></b-form-file>
                <b-input-group v-else>
                  <b-input
                    :state="true"
                    :value="dto.imageUrl"
                    :disabled="true"
                  ></b-input>
                  <b-input-group-append>
                    <b-button
                      size="sm"
                      variant="primary"
                      @click="openInNewTab(dto.imageUrl)"
                      >{{ $t("COMMON.DOWNLOAD") }}</b-button
                    >
                    <b-button
                      size="sm"
                      variant="secondary"
                      @click="dto.imageUrl = null"
                      >{{ $t("COMMON.REPLACE") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.AUTHOR')"
                label-for="field-name"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.author"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-latitude"
                label-cols-lg="2"
                :label="$t('COMMON.LATITUDE')"
                label-for="field-latitude"
              >
                <b-form-input
                  id="field-latitude-input"
                  v-model="dto.latitude"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-longitude"
                label-cols-lg="2"
                :label="$t('COMMON.LONGITUDE')"
                label-for="field-longitude"
              >
                <b-form-input
                  id="field-longitude-input"
                  v-model="dto.longitude"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-radius"
                label-cols-lg="2"
                :label="$t('DATA_PACKAGES.RADIUS')"
                label-for="field-radius"
              >
                <b-form-input
                  id="field-radius-input"
                  v-model="dto.radius"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-parameters"
                label-cols-lg="2"
                :label="$t('DATA_PACKAGES.PARAMETERS')"
                label-for="field-parameters"
              >
                <multi-suggestion
                  suggestion-processor="parameterSuggestionProcessor"
                  :element-name="$t('PARAMETERS.PARAMETER')"
                  :elements-name="$t('PARAMETERS.PARAMETERS')"
                  :selected-elements="selectedParametersForSuggestion"
                  :addErrorNotification="true"
                  @on-element-added="addParameter"
                  @on-remove-element="removeParameter"
                />
              </b-form-group>

              <b-button variant="primary" v-on:click="onShowModal">{{
                $t("DEVICE.CHOOSE_COORDINATES")
              }}</b-button>
            </b-card-body>
          </b-tab>

          <b-tab :title="$t('MENU.DEVICES')" v-if="dto.devices.length > 0">
            <b-table
              id="table-grants"
              striped
              bordered
              :items="dto.devices"
              :fields="deviceFields"
            >
              <template v-slot:cell(selected)="row">
                <b-form-group>
                  <input type="checkbox" v-model="row.item.selected" />
                </b-form-group>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>

    <b-modal size="lg" class="modal--widget" ref="my-modal">
      <template v-slot:modal-header="{ close, title }">
        <h5>{{ $t("MAP.CHANGE_POINT_AND_RADIUS") }}</h5>
        <b-button-close @click="close" class="button--close" />
      </template>

      <GmapMap
        :center="{ lat: 45, lng: 38 }"
        :zoom="4"
        map-type-id="terrain"
        style="width: 100%; height: 500px"
        ref="mapRef"
        :options="{
          streetViewControl: false,
          mapTypeControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false,
        }"
        @click="onSelectPoint"
        @zoom_changed="updateStore('zoom', $event)"
        @center_changed="updateStore('reportedCenter', $event)"
      >
        <GmapCircle
          v-if="circleData && circleData.coordinates"
          :center.sync="circleData.coordinates"
          :radius="circleData.radius"
          :draggable="true"
          :editable="true"
          @radius_changed="onRadius_changed"
          @center_changed="onCenter_changedCircle"
          :options="{
            strokeColor: '#FF0000',
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.05,
          }"
        />
      </GmapMap>

      <template v-slot:modal-footer>
        <div class="w-100">
          <p v-if="renderData" class="float-left">
            {{ renderData }}
            <b-button size="sm" @click="resetValue">{{
              $t("MAP.RESET")
            }}</b-button>
          </p>

          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="applyData"
          >
            {{ $t("COMMON.APPLY") }}
          </b-button>

          <b-button size="sm" class="float-right mr-2" @click="closeModal">
            {{ $t("COMMON.CLOSE") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import { LocalStore } from "@/common/LocalStore";
import MultiSuggestion from "../component/MultiSuggestion";
import ApiService from "../../core/services/api.service";

const ls = new LocalStore("component-map");
const radiusDefaultCircle = 100000; // in meters

export default {
  name: "data-package",
  components: {
    MultiSuggestion,
  },
  data() {
    return {
      save: {
        resource: "/api/data-package/save",
        requestType: "POST",
        requestParams: {},
      },

      image: null,

      dto: {
        name: null,
        author: null,
        description: null,
        latitude: null,
        longitude: null,
        imageUrl: null,
        radius: null,
        devices: [],
        parameters: [],
      },

      devices: [],

      deviceFields: [
        { label: this.$t("COMMON.NAME"), key: "name" },
        { label: this.$t("COMMON.TYPE"), key: "typeName" },
        { label: this.$t("COMMON.TEAM"), key: "teamName" },
        { label: this.$t("DEVICE.IMEI"), key: "imei" },
        { label: this.$t("COMMON.SELECTED"), key: "selected" },
      ],

      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: "",
      },

      dataForm: {
        selectedDevices: [],
        mapData: null,
        endDate: "",

        startDate: new Date().toISOString(),
        groupType: "",
        chartType: null,

        selectedParameters: [],
      },

      radiusDefaultCircle,

      circleData: this.value,

      timeZone: null,

      showOverlay: true,
      confirmPassword: null,

      selectedParametersForSuggestion: [],
      parametersObjectName: "",
      filterParameters: "",

      createNewStr: this.$t("DATA_PACKAGES.CREATE_NEW_DATA_PACKAGE"),
      editStr: this.$t("DATA_PACKAGES.EDIT_DATA_PACKAGE"),
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    itemsTotal() {
      return this.response.totalCount;
    },
    renderData() {
      if (_.isEmpty(this.circleData)) {
        return "";
      }
      const { coordinates, radius } = this.circleData;

      return `Lat: ${coordinates["lat"].toFixed(2)} Lon: ${coordinates[
        "lng"
      ].toFixed(2)}, radius ${radius} meters`;
    },
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.DATA_PACKAGES"), route: "/data-packages" },
      { title: title },
    ]);
    this.$store.dispatch(SET_ACTIONS, null);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.load();
    }

    this.showOverlay = false;
  },

  methods: {
    load: function () {
      return this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/data-package/" + this.$route.params.id,
          requestType: "GET",
          requestParams: { enrich: true },
        })
        .then((response) => {
          let data = response;
          let coordinates = { lat: data.latitude, lng: data.longitude };
          this.circleData = { coordinates: coordinates, radius: data.radius };
          for (const parameter of data.parameters) {
            this.selectedParametersForSuggestion.push(parameter);
          }
          for (const device of data.devices) {
            device.selected = true;
          }
          this.dto = data;
        })
        .catch(this.onError);
    },

    addParameter(data) {
      this.selectedParametersForSuggestion.push(data);
    },

    removeParameter(index, data) {
      this.selectedParametersForSuggestion.splice(index, 1);
    },

    openInNewTab(url) {
      window.open(url, "_blank");
    },

    onSaveClicked: async function () {
      this.showOverlay = true;
      const request = this.save;
      for (let i = 0; i < this.dto.devices.length; i++) {
        if (this.dto.devices[i].selected == false) {
          this.dto.devices.splice(i, 1);
          i--;
        }
      }
      this.dto.parameters.splice(0, this.dto.parameters.length);
      if (this.selectedParametersForSuggestion.length != 0) {
        for (const parameter of this.selectedParametersForSuggestion) {
          this.dto.parameters.push({ id: parameter.id });
        }
      }

      if (this.image != null) {
        const fmData = new FormData();
        fmData.append("image", this.image);
        let result = await ApiService.post("/api/resource", fmData);
        this.dto.imageUrl = result.data;
      }

      request.dto = this.dto;
      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError)
        .finally(() => {
          this.showOverlay = false;
        });
    },

    prepareTimeZone: function () {
      let timeZoneInv = new Date().getTimezoneOffset() / -60; // корректный часовой пояс
      // сделаем корректную строку для вывода часового пояса по ISO 8601:2004
      timeZoneInv < 0 ? (this.timeZone = "-") : (this.timeZone = "+"); // первый символ - добавляем знак
      Math.abs(timeZoneInv) < 10
        ? (this.timeZone = this.timeZone + "0" + Math.abs(timeZoneInv) + ":00")
        : (this.timeZone = this.timeZone + Math.abs(timeZoneInv) + ":00"); // получаем часовой пояс вида +03:00
    },

    openInfoWindowTemplate(index) {
      this.setInfoWindowTemplate(index);
      this.infoWindow.position = {
        lat: this.devices[index].lat,
        lng: this.devices[index].lng,
      };
      this.infoWindow.open = true;
    },

    setInfoWindowTemplate: function (index) {
      this.infoWindow.template =
        "<b>name</b> " +
        this.devices[index].name +
        "<br>" +
        "<b>created</b> " +
        this.devices[index].created.substring(0, 18).replace("T", " ");
    },

    onShowModal() {
      // update maps params
      let zoomMap = ls.getItem("zoom") || 10;
      let mapCenter = ls.getItem("center") || { lat: 55.78874, lng: 49.12214 };

      this.zoomMap = zoomMap;
      this.mapCenter = mapCenter;
      return this.$refs["my-modal"].show();
    },

    closeModal() {
      return this.$refs["my-modal"].hide();
    },

    resetValue() {
      this.circleData = null;
    },

    onSelectPoint(e) {
      const coordinates = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };

      this.circleData = {
        radius: radiusDefaultCircle,
        coordinates: coordinates,
      };
    },

    updateStore(key, e) {
      switch (key) {
        case "zoom":
          ls.setItem("zoom", e);
          break;
        case "reportedCenter": {
          let center = {
            lat: e.lat(),
            lng: e.lng(),
          };
          ls.setItem("center", center);
          break;
        }
      }
    },

    onCenter_changedCircle(latLng) {
      const coordinates = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      };

      if (_.isEqual(this.circleData.coordinates, coordinates)) {
        return;
      }

      this.circleData.coordinates = coordinates;
    },

    onRadius_changed(e) {
      this.circleData.radius = +e.toFixed(0);
    },

    applyData() {
      this.dto.latitude = this.circleData.coordinates.lat;
      this.dto.longitude = this.circleData.coordinates.lng;
      this.dto.radius = this.circleData.radius;
      this.loadDevices();
      this.closeModal();
    },

    loadDevices: function () {
      return this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/data-package/device",
          requestType: "GET",
          requestParams: {
            latitude: this.dto.latitude,
            longitude: this.dto.longitude,
            radius: this.dto.radius,
          },
        })
        .then((response) => {
          this.dto.devices.splice(0, this.dto.devices.length);
          if (response.data == null || response.data.length == 0) {
            this.$bvToast.toast(this.$t("DATA_PACKAGES.NO_DEVICE_FOUND"), {
              title: this.$t("COMMON.WARNING"),
              variant: "warning",
              autoHideDelay: 5000,
            });
          } else {
            for (const device of response.data) {
              device.selected = false;
              this.dto.devices.push(device);
            }
          }
        })
        .catch(this.onError);
    },
    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
